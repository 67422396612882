import * as React from 'react';
import {
  Box,
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Layout from '../../components/layout/CommonLayout/Layout';
import SEO from '../../components/atoms/SEO';
import GenericPage from '../../components/layout/CommonLayout/GenericPage';
import MyUserDetailsCard from '../../components/organisms/MyUserDetailsCard/MyUserDetailsCard';
import MyAccountSideBar from '../../components/pages/my-account/MyAccountSideBar/MyAccountSideBar';

import useMyUserDetails from '../../api/auth/me/useMyUserDetails';

import { useSignOut } from '../../hooks';
import useAuthToken from '../../store/auth/hooks/useAuthToken';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainCtn: {
      width: '100%',
      background: '#f3f3f3',

      padding: theme.spacing(7, 0),

      [theme.breakpoints.up('mm')]: {
        width: theme.breakpoints.values.mm,
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5, 0),
      },
    },
  })
);

// ========== COMPONENT ========== //

const MyAccountPage = () => {
  const classes = useStyles();

  const authToken = useAuthToken();
  const signOut = useSignOut();

  const {
    data: myUserDetails,
    error: myUserDetailsError,
    status: myUserDetailsStatus,
  } = useMyUserDetails({
    authToken,
  });

  const banner =
    'https://res.cloudinary.com/rmnfrk9/image/upload/c_limit,w_924,q_70/static/cover-photo-my-details_qigisf.jpg';

  return (
    <Layout>
      <SEO title="My account" />
      <GenericPage>
        <Box className={classes.mainCtn}>
          <Container>
            <Grid container>
              <Hidden mdDown>
                <Grid item xs={12} lg={3}>
                  <MyAccountSideBar
                    userData={myUserDetails?.user}
                    onSignOutButtonClick={() => signOut()}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} lg={9}>
                <Hidden mdDown>
                  <Box>
                    {banner ? (
                      <img
                        style={{
                          width: '100%',
                          height: 218,
                          objectFit: 'cover',
                        }}
                        alt="banner account"
                        src={banner}
                      />
                    ) : (
                      <Skeleton variant="rect" width="auto" height={218} />
                    )}
                  </Box>
                </Hidden>
                <MyUserDetailsCard
                  myUserDetails={myUserDetails?.user}
                  myOrganizationDetails={myUserDetails?.organization}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </GenericPage>
    </Layout>
  );
};

export default MyAccountPage;
