import * as React from 'react';
import {
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { CheckRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { Select, TextField, Typography } from '../../atoms';
import { Modal } from '../../molecules';
import EditableAvatar from '../MyProperties/EditableAvatar';
import { UserInDb } from '../../../api/auth/types';

// ========== TYPES ========== //

interface ComponentProps {
  myUserDetails: UserInDb | undefined;
  myOrganizationDetails:
    | {
        organizationId: number | undefined;
        organizationTitle: string | undefined;
      }
    | undefined;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      padding: theme.spacing(10.5, 12, 3, 12),
      backgroundColor: '#fff',
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(0),
        backgroundColor: 'transparent',
        padding: theme.spacing(0, 2, 2),
      },
    },

    gridItem: {
      marginBottom: 30,
      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
      },
    },

    gridItemLeft: {
      paddingRight: 42.5,
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },

    gridItemRight: {
      paddingLeft: 42.5,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },

    centerContent: {
      justifyContent: 'center',
      textAlign: 'center',
    },

    btnSubmit: {
      borderRadius: theme.spacing(2.5),
      width: 135,
      fontSize: theme.typography.h2.fontSize,
      textTransform: 'capitalize',
      lineHeight: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },

    modalContent: {
      maxWidth: 206,
      textAlign: 'center',
      margin: 'auto',
      color: '#000',
    },

    modalIcon: {
      width: 65,
      height: 65,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto 12px',
      borderRadius: '50%',
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
      padding: theme.spacing(2),
    },

    modalMessage: {
      marginBottom: theme.spacing(2),
    },
  })
);

// ========== COMPONENT ========== //

export default function MyUserDetailsCard({
  myUserDetails,
  myOrganizationDetails,
}: ComponentProps) {
  const classes = useStyles();

  const [openPopup, setOpenPopup] = React.useState(false);

  const togglePopup = (open?: boolean) => {
    open ? setOpenPopup(open) : setOpenPopup((openPopup) => !openPopup);
  };

  const onSubmit = () => {
    togglePopup(true);
  };

  const onCloseModal = () => togglePopup(false);

  return (
    <Box className={classes.section}>
      {myUserDetails ? (
        <Grid container>
          <Modal open={openPopup} onClose={onCloseModal} modalWidth={440}>
            <div className={classes.modalContent}>
              <CheckRounded className={classes.modalIcon} />
              <div className={classes.modalMessage}>
                <Typography component="h1" fontWeight="bold">
                  WOOHOO!
                </Typography>
                <Typography>
                  Your information has been successfully saved
                </Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.btnSubmit}
                onClick={onCloseModal}
              >
                OK
              </Button>
            </div>
          </Modal>
          <Hidden lgUp>
            <Grid item xs={12} className={classes.centerContent}>
              <EditableAvatar />
            </Grid>
          </Hidden>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemLeft, classes.gridItem)}
          >
            <TextField
              id="first_name"
              label="First Name"
              fullWidth
              defaultValue={myUserDetails.first_name}
              disabled
            />
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemRight, classes.gridItem)}
          >
            <TextField
              id="last_name"
              label="Last Name"
              fullWidth
              defaultValue={myUserDetails.last_name}
              disabled
            />
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemLeft, classes.gridItem)}
          >
            <TextField
              id="email"
              label="Email"
              fullWidth
              defaultValue={myUserDetails.email}
              disabled
            />
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemRight, classes.gridItem)}
          >
            <TextField
              id="phone"
              label="Phone"
              fullWidth
              defaultValue={myUserDetails.phone}
              disabled
            />
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemLeft, classes.gridItem)}
          >
            <Select
              defaultValue={myUserDetails.user_type}
              fullWidth
              label="User type"
              disabled
              options={[
                { value: '', label: ' ' },
                { value: 'landlord', label: 'Landlord' },
                { value: 'manager', label: 'Manager' },
                { value: 'admin', label: 'Admin' },
                { value: 'other', label: 'Other' },
              ]}
            />
          </Grid>
          {/* TODO: coming soon */}
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemRight, classes.gridItem)}
          >
            <Select
              label="Organisation"
              multiple={true}
              fullWidth
              defaultValue={[myOrganizationDetails?.organizationTitle ?? '']}
              disabled
              options={[
                {
                  value: myOrganizationDetails?.organizationTitle ?? '',
                  label: myOrganizationDetails?.organizationTitle ?? '',
                },
              ]}
            />
          </Grid>
          {/* TODO: coming soon */}
          {/*<Grid item xs={12} className={classes.gridItem}>*/}
          {/*  <MarkdownEditor value="" />*/}
          {/*</Grid>*/}
          <Grid
            item
            xs={12}
            className={clsx(classes.gridItem, classes.centerContent)}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.btnSubmit}
              onClick={onSubmit}
              disabled
            >
              Save
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Typography>No information available. Please sign in first.</Typography>
      )}
    </Box>
  );
}
